import React from 'react';
import { FormattedMessage } from 'react-intl';
import './TutorProfile.css';
import tutorImage from '../../static/tutor-photo.jpeg';

function TutorProfile() {
  return (
    <div className="tutor-profile">
      <div className="tutor-image-container">
        <img src={tutorImage} alt="Tutor" className="tutor-image" />
      </div>
      <div className="tutor-info">
        <h2 className="tutor-name">EKATERINA</h2>
        <h3 className="tutor-title">
          <FormattedMessage id="tutorProfile.title" />
        </h3>
        <p className="tutor-quote">日本はここから始まる: 学び、旅し、夢見よう!</p>
      </div>
    </div>
  );
}

export default TutorProfile;