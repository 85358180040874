import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import './NavBar.css';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

function NavBar() {
  return (
    <nav className="navbar">
      <div className="nav-items">
        <Link className="main" to={`/`}>
          <FormattedMessage id="navbar.main" />
        </Link>
        <HashLink smooth to="/#middle">
          <FormattedMessage id="navbar.whyLearnJapanese" />
        </HashLink>
        <HashLink smooth to="/#englessons">
          <FormattedMessage id="navbar.engLessons" />
        </HashLink>
        <HashLink smooth to="/#about_me">
          <FormattedMessage id="navbar.aboutMe" />
        </HashLink>
        <HashLink smooth to="/#reviews">
          <FormattedMessage id="navbar.reviews" />
        </HashLink>
        <HashLink smooth to="/#lesson_types">
          <FormattedMessage id="navbar.lessonTypes" />
        </HashLink>
        <HashLink smooth to="/#book">
          <FormattedMessage id="navbar.bookTrial" />
        </HashLink>
        <LanguageSwitcher />
      </div>
    </nav>
  );
}

export default NavBar;

