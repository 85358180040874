import React from 'react';
import { useIntl } from 'react-intl';
import {Select, MenuItem} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './LanguageSwitcher.css';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';

function LanguageSwitcher() {
  const intl = useIntl();

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    // Update the language in the URL or storage
    // For example, using URL:
    window.location.href = `/${lang}`;
    // Or using localStorage:
    // localStorage.setItem('language', lang);
    // window.location.reload();
  };

  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: '0.8em',
            backgroundColor: '#545454',
            border: 'none',
            top: useCheckMobileScreen() ? '0.15em' : '-0.1em',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.8em',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
        <FormControl 
            sx={{ m: 0.1, minWidth: 60 }}
            size="small"
        >
        <Select
            autoWidth={true}
            value={intl.locale}
            onChange={handleLanguageChange}
        >
            <MenuItem
                id="en"
                value={"en"}
            >
                English
            </MenuItem>
            <MenuItem
                id="ru"
                value={"ru"}
            >
                Русский
            </MenuItem>
            <MenuItem
                id="ua"
                value={"ua"}
            >
                Українська
            </MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

export default LanguageSwitcher;