import React from 'react';
import styles from './page.module.css';

// Components
import NavBar from '../../components/NavBar/NavBar';

import TrialLesson from '../../components/TrialLesson/TrialLesson';
import SocialLinks from '../../components/SocialLinks/SocialLinks';
import Footer from '../../components/Footer/Footer';

function PaymentPage() {

  return (
    <div className={styles.PaymentPage}>
      <NavBar />
      <div className={styles.Footer}>
        <TrialLesson />
        <SocialLinks />
        <Footer />
      </div>
    </div>
  );
}

export default PaymentPage;
