import React, {useEffect, useState} from 'react';
import { FormattedMessage } from 'react-intl';
import './Lessons.css';

function Lessons() {
  const [country, setCountry] = useState('');

  useEffect(() => {
    const fetchCountry = () => {
      const countryCode = window.COUNTRY_CODE || 'NL';
      setCountry(countryCode);
    };

    fetchCountry();
  }, []);

  let prices = {};
  switch (country) {
    case 'RU':
      prices.individual = "1500₽"
      prices.standard = "4500₽"
      prices.intensive = "6500₽"
      break
    default:
      prices.individual = "15€"
      prices.standard = "45€"
      prices.intensive = "65€"
      break
  }

  return (
    <section id="lesson_types" className="lessons">
      <div className="lesson-types">
        <div className="lesson">
          <h3>
            <FormattedMessage id="lessons.individual.title" />
          </h3>
          <p>
            {prices.individual}<FormattedMessage id="lessons.individual.price" />
          </p>
        </div>
        <div className="lesson">
          <h3>
            <FormattedMessage id="lessons.standard.title" />
          </h3>
          <p>
            <FormattedMessage id="lessons.standard.description" />
          </p>
          <p>
            {prices.standard}<FormattedMessage id="lessons.standard.price" />
          </p>
        </div>
        <div className="lesson">
          <h3>
            <FormattedMessage id="lessons.intensive.title" />
          </h3>
          <p>
            <FormattedMessage id="lessons.intensive.description" />
          </p>
          <p>
            {prices.intensive}<FormattedMessage id="lessons.intensive.price" />
          </p>
        </div>
      </div>
    </section>
  );
}

export default Lessons;

