import React from 'react';
import './page.css';

// Components
import NavBar from '../../components/NavBar/NavBar';
import TutorProfile from '../../components/TutorProfile/TutorProfile';
import Testimonial from '../../components/Testimonial/Testimonial';
import Lessons from '../../components/Lessons/Lessons';
import Footer from '../../components/Footer/Footer';
import Middle from '../../components/Middle/Middle';
import AboutMe from '../../components/AboutMe/AboutMe';
import Reviews from '../../components/Reviews/Reviews';
import SocialLinks from '../../components/SocialLinks/SocialLinks';
import TrialLesson from '../../components/TrialLesson/TrialLesson';
import AboutEngLessons from '../../components/AboutMe/AboutEngLessons';

function MainPage() {

  return (
    <div className="MainPage">
      <NavBar />
      <TutorProfile />
      <Testimonial />
      <Middle />
      <AboutMe />
      <AboutEngLessons />
      <Reviews />
      <Lessons />
      <div>
        <TrialLesson />
        <SocialLinks />
        <Footer />
      </div>
    </div>
  );
}

export default MainPage;

