import React from 'react';
import './SocialLinks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function SocialLinks() {
  return (
    <div className="my_links">
      <a href="https://t.me/JapaneseBenkyou" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTelegram} size="2x" />
      </a>
      <a href="https://www.instagram.com/katerina_japanese" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="mailto:benkyokaijapanese@gmail.com">
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
      </a>
    </div>
  );
}

export default SocialLinks;
