import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { FormattedMessage } from 'react-intl';
import 'react-awesome-button/dist/styles.css';
import './TrialLesson.css';
import "react-datepicker/dist/react-datepicker.css";

function TrialLesson() {
  const [formVisible, setFormVisible] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    telegram: '',
    instagram: '',
    email: '',
    dates: [new Date(), new Date()]
  });

  
  const openForm = () => {
    setFormVisible(true);
    // Reset the form and success message states
    setFormData({
      name: '',
      telegram: '',
      instagram: '',
      email: '',
      dates: [new Date(), new Date()]
    });
    setSubmissionSuccess(false); // Ensure the success message is hidden when reopening the form
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDateChange = (date, index) => {
    const newDates = formData.dates.map((currentDate, i) => i === index ? date : currentDate);
    setFormData(prevState => ({
      ...prevState,
      dates: newDates
    }));
  };

  const addDatePicker = () => {
    setFormData(prevState => ({
      ...prevState,
      dates: [...prevState.dates, new Date()]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let contact = '';
    if (formData.email !== '') {
      contact += "Email: "+formData.email + " ";
    }
    if (formData.telegram !== '') {
      contact += "Telegram: "+formData.telegram + " ";
    }
    if (formData.instagram !== '') {
      contact += "Instagram: "+formData.instagram + " ";
    }
    const postData = {
      name: formData.name,
      contact: contact,
      dates: formData.dates
    }

    try {
      const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_LOCAL;
      const response = await fetch(`${apiUrl}/api/v1/trial-lesson`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });
  
      if (response.ok) {
        // Successful submission
        setFormVisible(false);
        setSubmissionSuccess(true); // Show success message
      } else {
        // Handle error response
        console.error('Error submitting form:', response.statusText);
        // You can display an error message to the user here
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error submitting form:', error);
      // You can display an error message to the user here
    }
  };

  const handleCancel = () => {
    setFormVisible(false);
  };

  return (
    <section id="book" className="TrialLesson">
      {!submissionSuccess && (
        <button className="book-button" onClick={openForm}>
          <FormattedMessage id="trialLesson.bookButton" />
        </button>
      )}
      {formVisible && (
        <div className="modal-overlay">
          <div className="modal">
            <form className="trial-form" onSubmit={handleSubmit}>
              <label>
                <FormattedMessage id="trialLesson.form.name" />
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
                <FormattedMessage id="trialLesson.form.howToContactText" />
                <br/><br/>
              <label>
                <span className="contact-icon telegram-icon"></span>
                <FormattedMessage id="trialLesson.form.telegram" />
                <input
                  type="text"
                  name="telegram"
                  value={formData.telegram}
                  onChange={handleChange}
                />
              </label>
              
              <label>
                <span className="contact-icon instagram-icon"></span>
                <FormattedMessage id="trialLesson.form.instagram" />
                <input
                  type="text"
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleChange}
                />
              </label>
              
              <label>
                <span className="contact-icon email-icon"></span>
                <FormattedMessage id="trialLesson.form.email" />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </label>
              
              <label>
                <FormattedMessage id="trialLesson.form.datePrompt" />
              </label>
              
              <label>
                <FormattedMessage id="trialLesson.form.dateInstruction" />
              </label>
              
              {formData.dates.map((date, index) => (
                <DatePicker
                  key={index}
                  selected={date}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy HH:mm"
                  timeIntervals={15}
                  onChange={date => handleDateChange(date, index)}
                  minDate={new Date()}
                />
              ))}
              
              <button className="add_date_btn" type="button" onClick={addDatePicker}>
                <FormattedMessage id="trialLesson.form.addDateButton" />
              </button>
              
              <div className="form-actions">
                <button type="submit" className="submit-button">
                  <FormattedMessage id="trialLesson.form.submitButton" />
                </button>
                <button type="button" className="cancel-button" onClick={handleCancel}>
                  <FormattedMessage id="trialLesson.form.cancelButton" />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
}

export default TrialLesson;
