import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import './Reviews.css';
import review_1 from '../../static/reviews/review_1.jpeg';
import review_2 from '../../static/reviews/review_2.jpeg';
import review_3 from '../../static/reviews/review_3.jpeg';
import review_4 from '../../static/reviews/review_4.jpeg';
import review_5 from '../../static/reviews/review_5.jpeg';

const reviewPhotos = {
  review_1,
  review_2,
  review_3,
  review_4,
  review_5,
};

function Reviews() {
  const intl = useIntl();
  const placeholderReviews = Object.entries(intl.messages)
    .filter(([key]) => key.startsWith('reviews.'))
    .reduce((acc, [key, value]) => {
      const [, index, prop] = key.split('.');
      acc[index] = { ...acc[index], [prop]: value };
      return acc;
    }, []);

  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const currentReview = placeholderReviews[currentReviewIndex];

  const handleNextClick = () => {
    const nextIndex = (currentReviewIndex + 1) % placeholderReviews.length;
    setCurrentReviewIndex(nextIndex);
  };

  return (
    <section id="reviews" className="Reviews">
      <div className="core">
        {currentReview.type === 'text' ? (
          <div className="review_block text_review">
            <div className="student_photo">
              <img src={reviewPhotos[currentReview.photo]} alt="Student" />
            </div>
            <div className="student_info">
              <div className="student_name">{currentReview.name}</div>
              <div className="student_text">{currentReview.text}</div>
            </div>
          </div>
        ) : (
          <div className="review_block video_review">
            <iframe
              src={currentReview.videoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video Review"
            ></iframe>
          </div>
        )}
        <div className="next_button" onClick={handleNextClick}>
          ➤
        </div>
      </div>
    </section>
  );
}

export default Reviews;