import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import MainPage from './pages/root/page';
import reportWebVitals from './reportWebVitals';
import { flatten } from 'flat'
import { IntlProvider } from 'react-intl';

// Translations
import en from './translations/en.json';
import ru from './translations/ru.json';
import ua from './translations/ua.json';
import PaymentPage from './pages/payment/page';

const router = createBrowserRouter([
  {
    path: "*",
    element: <MainPage />,
  },
  {
    path: ":lang?/payment/:lessonType?",
    element: <PaymentPage />
  }
]);

const translations = {
  en,
  ru,
  ua,
};

let language = "";
const root = ReactDOM.createRoot(document.getElementById('root'));
let langFromLocation = window.location.pathname.slice(1).split("/")[0] || 'en';
if (!(langFromLocation in translations)) {
  language = "en";
} else {
  language = langFromLocation;
}

root.render(
  <React.StrictMode>
    <IntlProvider messages={flatten(translations[language])} locale={language}>
      <RouterProvider router={router}>
      </RouterProvider>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
