import React from 'react';
import { FormattedMessage } from 'react-intl';
import './AboutMe.css';
import aboutMeImage from '../../static/about-me-photo.jpeg';

function AboutMe() {
  return (
    <section id="about_me" className="AboutMe">
      <div className="am_core">
        <div className="am_left_block">
          <div className="am_text_wrapper">
            <p>
              <FormattedMessage id="aboutMe.description" />
            </p>
          </div>
        </div>
        <div className="am_right_block">
          <div className="image_wrapper">
            <img src={aboutMeImage} alt="About Me" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;