import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Middle.css';
import middleImage from '../../static/middle.jpeg';


function Middle() {
  return (
    <section id="middle" className="middle">
      <div className="middle_block">
        <div className="left_block">
          <div className="picture_block">
            <img src={middleImage} className="tori_left" alt="Middle" />
          </div>
        </div>
        <div className="right_block">
          <div className="text_block">
            <h2>
              <FormattedMessage id="middle.title" />
            </h2>
          </div>
          <div className="checkmark_item">
            <h2>
              <FormattedMessage id="middle.reason1" />
            </h2>
          </div>
          <div className="checkmark_item">
            <h2>
              <FormattedMessage id="middle.reason2" />
            </h2>
          </div>
          <div className="checkmark_item">
            <h2>
              <FormattedMessage id="middle.reason3" />
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Middle;

