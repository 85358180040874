import React from 'react';
import { FormattedMessage } from 'react-intl';
import './AboutEngLessons.css';
import aboutEngLessonsImage from '../../static/1-about-eng-lessons.jpeg';

function AboutEngLessons() {
  return (
    <section id="englessons" className="EngLessons">
      <div className="englessons_core">
      <div className="englessons_right_block">
          <div className="image_wrapper">
            <img src={aboutEngLessonsImage} alt="About Me" />
          </div>
        </div>
        <div className="englessons_left_block">
          <div className="englessons_text_wrapper">
            <p className="p1">
              <FormattedMessage id="aboutEngLessons.description.p1" />
            </p>
            <p className="p3">
              <FormattedMessage id="aboutEngLessons.description.p3" />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutEngLessons;