import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Testimonial.css';

function Testimonial() {
  return (
    <div className="testimonial">
      <blockquote className="testimonial-quote">
        <FormattedMessage id="testimonial.quote" />
      </blockquote>
    </div>
  );
}

export default Testimonial;